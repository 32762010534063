{
  "name": "player",
  "version": "6.51.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --port 3000 --configuration development",
    "start:prod": "ng serve --port 3000 --configuration production",
    "build": "ng build && npm run gVersion && pnpm sentry:sourcemaps",
    "build:development": "ng build --configuration=development && npm run gVersion && pnpm sentry:sourcemaps",
    "build:production": "ng build --configuration=production && npm run gVersion && pnpm sentry:sourcemaps",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "gVersion": "node generateVersion.js",
    "serve": "serve -s dist/player/browser",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org admefy --project player ./dist/player/browser && sentry-cli sourcemaps upload --org admefy --project player ./dist/player/browser"
  },
  "private": true,
  "dependencies": {
    "@admefy/domain": "^5.1.16",
    "@admefy/jswebrtc": "^2.0.1",
    "@angular/animations": "^18.2.8",
    "@angular/common": "^18.2.8",
    "@angular/compiler": "^18.2.8",
    "@angular/core": "^18.2.8",
    "@angular/forms": "^18.2.8",
    "@angular/platform-browser": "^18.2.8",
    "@angular/platform-browser-dynamic": "^18.2.8",
    "@angular/router": "^18.2.8",
    "@angular/service-worker": "^18.2.8",
    "@ng-web-apis/common": "^4.10.1",
    "@ngrx/operators": "^18.1.0",
    "@ngrx/signals": "^18.1.0",
    "@sentry/angular": "^8.34.0",
    "@sentry/cli": "^2.37.0",
    "animate.css": "^4.1.1",
    "core-js": "^3.38.1",
    "hls.js": "^1.5.16",
    "ngxtension": "4.0.0",
    "plyr": "^3.7.8",
    "random-gradient": "^0.0.2",
    "randomcolor": "^0.6.2",
    "rxjs": "~7.8.1",
    "scheduler-polyfill": "^1.2.1",
    "semver": "^7.6.3",
    "socket.io-client": "^4.8.0",
    "tslib": "^2.7.0",
    "twemoji": "^14.0.2",
    "wavesurfer.js": "^7.8.6",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.8",
    "@angular/cli": "^18.2.8",
    "@angular/compiler-cli": "^18.2.8",
    "@types/jasmine": "~5.1.4",
    "@types/random-gradient": "^0.0.2",
    "@types/randomcolor": "^0.5.9",
    "@types/semver": "^7.5.8",
    "autoprefixer": "^10.4.20",
    "browserslist": "^4.24.0",
    "ctix": "^2.7.0",
    "jasmine-core": "~5.3.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.47",
    "prettier": "^3.3.3",
    "serve": "^14.2.3",
    "tailwindcss": "^3.4.13",
    "typescript": "~5.6.3"
  },
  "packageManager": "pnpm@9.12.1+sha512.e5a7e52a4183a02d5931057f7a0dbff9d5e9ce3161e33fa68ae392125b79282a8a8a470a51dfc8a0ed86221442eb2fb57019b0990ed24fab519bf0e1bc5ccfc4"
}
